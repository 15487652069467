import {qSelectObj} from '@/components/models';
import Notify from 'quasar/src/plugins/Notify.js';;

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $utils: Utils;
  }
}

class Utils {
  
  notify({icon = 'announcement', message = '', type = 'warning', timeout=2500}){
    Notify.create({
      message,
      icon,
      type,
      position: 'bottom-right',
      progress: true,
      timeout
    })
  }
  

  valEmail(email = ''){
    if(email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )){
        return true;
      }
      return false;
  }
  
  valPhone(phone = ''){
    if(phone
      .toLowerCase()
      .match(
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
      )){
        return true;
      }
      return false;
  }

  valCedula(cedula = ''){
    if(cedula
      .toLowerCase()
      .match(
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{7}[\s.-]?\d{1}$/
      )){
        return true;
      }
      return false;
  }

  formatDate(d:string, s = ' - '){
      if(d){
          const date = d.slice(0, 19).split("T")[0].split("-");
          return date[2]+s+date[1]+s+date[0];
      }

      return '';
  }
  capitalize(st: string){
    if(!st) return;

    const splitted = st.split(' ');

    const s = splitted.reduce((pv, cv) => {
      return pv += ' ' + cv.charAt(0).toUpperCase() + cv.slice(1).toLowerCase();
    }, '')

    return s;

  }
  
  getLabels(data: qSelectObj[], limit = true){
    const d = [...data]
    return d.reduce((pv: string, cv: qSelectObj, index, arr) => {
      if(index == 3 && limit){
        arr.splice(3);
        return pv;
      }else{
        if(index == 2 && limit)
          return pv += cv.label+'... ';
        else if(index == d.length -1)
          return pv += cv.label;
          return pv += cv.label+', ';
      }
    }, '')
  }

  getPName(pId: string | number, data: qSelectObj[]){

    const found = data.filter(d => { return d.value == pId; });
    if(found.length > 0) return found[0].label;
     return 'Seleccione una provincia';

  }

}



const util = new Utils();


export { util };
