import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import Api from '@/utils/axios';
const api = new Api('lr');

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
      const a = document.getElementById('app');
      if(a) a.scrollIntoView({ behavior: 'smooth' });
  }
})

export default router


router.beforeResolve(async (to) => {

  const routes = ['/', '/nosotros', '/resultados', '/productos', '/empleos']

  if(routes.includes(to.path) || to.params.job || (to.params.jobId && to.params.reqId)){

    const slicedPath = to.path.slice(0, 150);
    api.post('/logger/visit', {route: slicedPath})

  }

    

})