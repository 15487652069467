
import './styles/quasar.scss'
import './styles/homeViewResults.scss'
import iconSet from 'quasar/icon-set/fontawesome-v5.js'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import es from 'quasar/lang/es'

import Notify from 'quasar/src/plugins/Notify.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    
  },
  extras: [
    // 'ionicons-v4',
    // 'mdi-v5',
     'fontawesome-v6',
    // 'eva-icons',
    // 'themify',
    // 'line-awesome',
    // 'roboto-font-latin-ext', // this or either 'roboto-font', NEVER both!

    // 'roboto-font', // optional, you are not bound to it
    // 'material-icons', // optional, you are not bound to it
  ],

  plugins: [Notify],
  iconSet: iconSet,
  lang: es
}