import { createApp, markRaw } from 'vue'
import App from './App.vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options';
const api = new Api('lr');


import { createPinia } from 'pinia';
const pinia = createPinia();

pinia.use(({ store }) => {
    store.$router = markRaw(router)
});



const app = createApp(App);


app .use(pinia)
    .use(Quasar, quasarUserOptions)
    .use(router)
    .mount('#app');

import { useGeneralStore } from './stores/generalStates';
    
import { util } from './utils/utils';
import Api from './utils/axios';
app.config.globalProperties.$api = new Api('lr');
app.config.globalProperties.$GRapi = new Api('gr');
app.config.globalProperties.$utils = util; 

router.beforeEach((to, from) => {


  if(to.path == "/qr"){
    const slicedPath = to.path.slice(0, 150);
    api.post('/logger/visit', {route: slicedPath})
    return "/resultados"
  }

  const gStore = useGeneralStore();
    if(Number(from.name) > Number(to.name)){
      gStore.$state.transition = "slide-right";
    }
    else{
      gStore.$state.transition = "slide-left";
    }

   


})