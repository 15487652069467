import { RouteRecordRaw } from 'vue-router';



const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
                //Home
                { path: '', name: "1", component: () => import('@/views/homeView.vue') },
                { path: '/nosotros', name: "2", component: () => import('@/views/usView.vue') },
                { path: '/resultados', name: "3", component: () => import('@/views/resultsView.vue') },
                { path: '/productos', name: "4", component: () => import('@/views/productsView.vue') },

                { 
                  path: '/empleos', name: "5",
                  component: () => import('@/layouts/emptyLayout.vue'),
                  children: [

                    { path: '', name: "6", component: () => import('@/views/job/jobsView.vue') },
                    { path: ':job', props: route => ({ jobId: route.params.job}), name: "7", component: () => import('@/views/job/jobView.vue') },
                  
                  ] 
                },


              ],
  },
  { path: '/empleos/ofertas/:jobId/:reqId', props: route => ({ jobId: route.params.jobId, reqId: route.params.reqId, answer: getValidAns(String(route.query.ans))}), name: "8", component: () => import('@/views/job/jobAcceptance.vue') },
  
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/components/util/ErrorNotFound.vue'),
  },
];

function getValidAns(ans: string){
  if(!ans) return 'unknown';

  switch(ans.toLowerCase()){
    case 'yes': return 'yes';
    case 'no': return 'no';
    default: return 'unknown';
  }
}


export default routes;
