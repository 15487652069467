import axios, { AxiosInstance } from 'axios';
// import { util } from '@/utils/utils'

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $api: Api;
    $GRapi: Api;
  }

}


class Api {
  private env = process.env.NODE_ENV;
  //Add https ip address 
  
  private baseUrl = this.env === 'development' ? 'http://10.0.11.3:5500/api/' : 'https://ov.gruporeal.com.do/api/';
  private api: AxiosInstance;

  constructor(target: string){

    this.baseUrl += target;
    this.api = axios.create({ baseURL: this.baseUrl, withCredentials: true });

  }

  getBaseUrl(){
    return this.baseUrl;
  }
  
  async get(route: string, reqQuery={}){
      try {

        const rows = await this.api.get(route, { params: reqQuery });
        // if(rows.data.data.nodb){
        //   // util.notify({message: 'No se pudo establecer conexión con la base de datos'});
        //   return {success: true, data: []};
        // }


        // if(rows.data.success === false){
        //     // util.notify({message: 'Error del servidor'});
        //   }
        return rows.data;



    } catch (error) {
        // util.notify({message: 'Error del servidor', type: 'negative'});
        return {success: false, data: []};

    }
  }
  async post(route: string, body: object, shouldNotify=true, headers={}){
      if(!body) return 'No data to post';

    try {


      const rows = await this.api.post(route, body, { headers });

      if(rows.data.data.nodb){
        // util.notify({message: 'No se pudo establecer conexión con la base de datos'});
      }

      return rows.data;

    } catch (error) {

      if(shouldNotify)
        // util.notify({message: 'Error del servidor', type: 'negative'});
        return {success: false, data: []};

    }
  }
}



export default Api;
