
import { defineStore } from 'pinia';

export const useGeneralStore = defineStore('general', {
  state: () => ({
    transition: "slide-left",

    jobSection: true,
    
  }),
  getters: {
  },
  actions: {
  },
});
